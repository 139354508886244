@import "../../../App.scss";

.DeliveryPartners {
  background-color: $white-gray;
  max-width: 40rem;
  margin-top: 1rem;
  @media (min-width: 992px) {
    max-width: 60rem;
  }

  h3 {
    font-size: 1.4rem;
    margin: 0.5rem auto 2rem auto;
  }

  @media only screen and (max-width: 404px) {
    width: 100%;
  }

  @media (min-width: 405px) and (max-width: 767px) {
    width: 80%;
  }

  .desktop-back-arrow {
    margin-right: 0.1rem;
  }

  .container {
    padding-left: 0 !important;
  }

  .card-title {
    margin-left: -15px;
  }
}
