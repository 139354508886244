.icon-style {
  width: fit-content;
  align-self: center;
  color: #002f43;
}

.modal-header {
  background-color: #ecedee;
  padding-bottom: 5%;
  text-align: center;
}

.icon-help {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.help-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  gap: 2%;
  padding-bottom: 3%;
  padding-top: 2%;
}

.map-help {
  display: flex;
  justify-content: center;
  // flex-wrap: wrap;
  margin-top: 2%;
  width: 100%;
  //height: 12vh;
  flex-direction: column;
}

.text {
  text-align: left;
  width: 80%;
  font-family: Apercu-regular;
  font-style: normal;
  font-weight: 400;
  align-self: center;
}


.text-footer{
    align-self: center;
    font-family: Apercu-regular;
    font-style: normal;
    font-size: 12px;
    padding-top: 2%;
}

.light-bulb-icon:hover {
  color: #B00027;
}