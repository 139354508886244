@import "../../../App.scss";

.table-header-field{
    font-family: Apercu-bold;
    font-style: normal;
    font-size: 21px;
    color: #5B6770;
    margin: 1%;
    display: flex;
  }

  .table-header-section{
    min-width: 100%;
    height: 10%;
    background-color: #ECEDEE;
    display: flex;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 999;
    overflow: scroll;
    font-family: Apercu-bold;
  }

  .active-page-num-text{
    font-family: Apercu-medium;
    font-style: normal;
    font-size: 21px;
    color: $primary;
    margin-left: 4%;
    margin-right: 4%;
}

.active-page-num-text:hover{
  font-family: Apercu-medium;
  font-style: normal;
  font-size: 21px;
  color: $primary;
  margin-left: 4%;
  margin-right: 4%;
  text-decoration: underline;
}

.inactive-page-num-text{
    font-family: Apercu-medium;
    font-style: normal;
    font-size: 21px;
    color: #5B6770;
    margin-left: 4%;
    margin-right: 4%;
}

.inactive-page-num-text:hover{
  font-family: Apercu-medium;
  font-style: normal;
  font-size: 21px;
  color: #5B6770;
  margin-left: 4%;
  margin-right: 4%;
  text-decoration: underline;
}

.ellipses{
  font-family: Apercu-medium;
  font-style: normal;
  font-size: 21px;
  color: #5B6770;
  margin-left: 4%;
  margin-right: 4%;
}

.error-text{
  font-family: Apercu-bold;
  font-style: bold;
  font-size: 30px;
  margin-left: 2%;
  margin-right: 2%;
  color: #5B6770;
  
}

  .page-num-holder{
    height: 10%;
    display: flex;
    justify-content: flex-end;
    justify-self: flex-end;
    align-items: center;
    align-self: center;
  }

  .table-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    width: 100%;
    max-height: 90%;
    flex-direction: column;
  }

  .table-tbody {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #ECEDEE;
    width: 100%;
    max-height: 100%;
    flex-direction: column;
    scrollbar-width: none
  }
  

  .pointer-cursor {
    cursor: pointer;
    font-family: Apercu-bold;
    font-style: normal;
    font-size: 21px;
    color: #5B6770;
    margin: 1%;
    display: flex;
  }


  .no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 79vw;
    height: 40vh;
    margin-bottom: 2%;
    font-family: Apercu-regular;
    font-style: normal;
    font-size: 25px;
  }

  .scroll-container {
    max-height: 50vh;
    overflow: scroll;
  }