@import "../../../../App.scss";

.desktop-back-link {
  font-size: 0.85rem !important;
  font-family: Apercu-Bold;
  text-align: left;
  color: $default !important;
  cursor: pointer !important;
}

.desktop-back-arrow {
  margin-right: 0.1rem;
}
