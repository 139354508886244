.home-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header-div {
  width: 100vw;
  height: 10%;
}

.body-div {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.SearchAndTags {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 1%;
  overflow: scroll;
}


.SpaceDiv {
  width: 15%;
  margin-right: 1%;
  margin-left: 1%; 
}

.SearchAndResults {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 90%;
  position: relative;
}

.SearchContainer {
  display: flex;
  width: 100%;
  height: 100%;
}

.search-tags{
  display: flex; 
  flex-wrap: wrap;
  height: 85%;
  align-self: flex-end;
  width: 40%;
}

.results-and-col-editor {
  display: flex;
  min-width: 100%;
  max-width: 100%;
}

.results-container {
  align-self: flex-start;
  font-family: Apercu-regular;
  color: #5B6770;
  margin-left: 0%;
  margin-top: 1%;
  width: 50%;
}

.filter-and-table {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 83%;
}

.filter-container{
  width: 15%;
  margin-right: 1%;
  margin-left: 1%;
  height: 65vh
}

.footer {
  height: 2%;
  background-color: #D9D9D9;
  font-size: 10px;
  justify-self: flex-end;
  justify-content: flex-end;
  font-family: Apercu;
  color: #5B6770;
  text-align: center;
  width: 100%;
}

.TableContainer{
  width: 83vw;
  align-self: flex-start;
  height: 75vh;
  overflow: scroll;
  padding-right: 3vw;
}

.TableContainer::-webkit-scrollbar{
  display: none;
}

.map {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 50vh;
  flex-direction: column;
  font-family: Apercu-regular;
    font-style: normal;
    font-size: 16px;
    color: #5B6770;
}

.leaflet-container{
  width: 75vw;
  height: 45vh;
}

.loading-page {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Apercu-regular;
  color: #5B6770;
  font-size: 30px;
}

.cfa-tag {
  height: 70% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 0 !important;
  margin-bottom: 1% !important;
}

.cfa-typography-variant-caption1 {
  font-size: 15px !important;
  height: fit-content !important;
}

.column-editor {
  min-width: 50%;
  max-width: 50%;
  display: flex;
  justify-content: flex-end;
}

.pin-icon:hover {
  color: #B00027;
}

.download-icon:hover {
  color: #B00027;
}