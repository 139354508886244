@import "../../../App.scss";

.location-selector {
  .location-selector-title {
    font-size: 1rem;
    color: $secondary-purple;
    padding: 1rem 2rem;
  }

  .location-flatlist {
    max-height: 2rem !important;
  }

  .location-list-item {
    padding: 0;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    margin-right: 2rem;
    cursor: pointer;
    border: none;
  }

  .location-list-item:hover {
    h3 {
      color: $secondary-purple;
    }

    h4 {
      color: $navy;
    }

    p {
      color: $teal;
    }
  }

  .location-item-avatar {
    align-self: center;
    height: 1.5rem;
  }

  .location-item-details {
    margin-top: -2rem;
    padding-top: 2.75rem;
    margin-left: -5rem;
    z-index: 2;

    .location-store-name {
      font-size: 0.9rem;
      text-overflow: ellipsis;
      width: 12rem;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-align: left;
    }

    .location-store-number {
      font-size: 0.95rem;
      width: 12rem;
      margin: 0.75rem 0 .5rem 0;
      color: $default;
      font-family: Apercu-Bold;
    }

    .location-store-address {
      font-size: 0.7rem;
      text-overflow: ellipsis;
      color: $default;
      font-family: Apercu-Bold;
      width: 12rem;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-align: left;
    }
  }

  input,
  input:focus {
    width: 90%;
    margin: -0.5rem auto 1rem auto !important;
    font-size: 0.9rem;
  }

  input::placeholder {
    font-size: 0.85rem;
  }

  .input-hint {
    color: white;
  }

  input.rbt-input-hint {
    display: none;
  }

  input:focus {
    outline: 0;
    box-shadow: none !important;
  }

  .dropdown-item {
    color: $default;
    font-family: Apercu-Medium;
    font-size: 0.85rem;
    text-align: center;
    background-color: $white;
    border-radius: 3px;
  }

  .dropdown-item:hover {
    color: $secondary-purple;
  }

  p.location-loading-text {
    margin-left: -2.5rem;
    text-align: center;
    justify-content: flex-end;
  }

  .load-more-button {
    background-color: $white;
    border-color: $white;
    color: $navy;
    margin: 0 0 0.5rem 3rem;
    font-size: 0.85rem;
  }
}
