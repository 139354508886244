.search-container{
    height: 100%;
    width: 41%;
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
}

.search-bar{
    background: #ffffff;
    border: 1px solid #a7acaf;
    border-radius: 10px;
    padding-left: 2%;
    width: 100%;
    height: 80%;
    min-height: 27px;
}
