.header-container{
    width: 100%;
    padding: 8% 0% 5% 8%; 
    height: 13%;
}

.subfilter-container{
    padding: 0% 10% 8% 15%;
    width: 100%;
}

.filter-box{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: inherit;
}

.filter-content{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 80%;
    width: 100%;
    flex-direction: column;
    overflow: scroll;
    margin-bottom: 5%;
    position: relative;
}

.cfa-checkbox {
    width: 16px;
    height: 16px
}

.cfa-checkbox-filled {
    width: 16px;
    height: 16px
}

.cfa-icon.cfa-icon-xs {
    width: 15px;
    height: 15px;
    vertical-align: top;
}

.cfa-input-label {
    display: flex;
    justify-content: center;
    align-items: center;
}