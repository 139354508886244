@import '../../../../App.scss';

  .table-entry-section{
    width: 100%;
    height: 10%;
    background-color: #F9F9F9;
    display: flex;
    margin-bottom: 0.5%;
    overflow-x: scroll;
  }

  .table-entry-section::-webkit-scrollbar{
    display: none;
  }

  .table-entry-section:hover{
    width: 100%;
    height: 10%;
    background: rgba(0, 106, 152, 0.18);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    overflow-x: scroll;
  }

  .table-entry-section:hover .table-entry-field{
    font-family: Apercu-regular;
    font-style: normal;
    font-size: 16px;
    color: #003E59;
    margin: 1%;
    display: flex;
  }

  .table-entry-info-icon:hover{
    color: $primary
  }

  .table-entry-field{
    font-family: Apercu-regular;
    font-style: normal;
    font-size: 16px;
    color: #5B6770;
    margin: 1%;
    display: flex;
    min-width: 12%;
    max-width: 12%;
    align-self: center;
    overflow-wrap: anywhere;
  }

  .more-information{
    font-family: Apercu-regular;
    font-style: normal;
    font-size: 16px;
    color: #5B6770;
    text-align: left;
  }

  .nav-bar-modal{
    font-family: Apercu-medium;
    font-style: normal;
    font-size: 16px;
    color: #919aa2;
    margin-bottom: 3%;
    margin-top: 3%;
  }

  .nav-bar-modal-title{
    font-family: 'Caecilia';
    font-style: normal;
    font-weight: 850;
    font-size: 16px;
    color: $primary;
    margin-bottom: 6%;
  }

  .loading-page-entry {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .my-modal-header {
    background-color: #ECEDEE;
    padding-bottom: 5%;
    border-radius: 6px;
  }

  .section-name {
    background-color: #ECEDEE;
    cursor: pointer;
  }

  .modal-body {
    display: flex;
    height: 70vh;
    padding-top: 0% !important;
  }

  .info-menu {
    width: 20%;
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    text-align: start;
  }

  .info-body {
    width: 80%;
    overflow: scroll;
    text-align: start;
    margin-left: 1%;
  }