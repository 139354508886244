@import "../../../App.scss";

.navbar {
  background-color: $white;
  height: 3rem;
  justify-content: center;
  text-align: center;
  padding-right: 1.25rem;
  border-bottom: 1px solid $nav-border;

  .nav-app-logo {
    margin-top: -1rem;
    position: absolute;
  }

  .nav-app-logo-container {
    cursor: pointer;
  }

  .admin-navbar-brand {
    h3 {
      margin: auto -2.5rem auto 2rem;
      font-size: 0.9rem;
      font-family: Apercu-medium;
    }
  }
}

.location-selector-icon {
  margin-right: 1rem;
  justify-content: center;
  align-self: center;
}

.location-selector-icon:hover {
  cursor: pointer;
}

.location-selector-card {
  Button {
    background-color: $navy;
  }
}

.selected-location-container {
  margin-right: 0.6rem;
  justify-content: flex-end;

  .selected-location-number {
    font-size: 0.85rem;
    font-family: Apercu-Medium;
    color: $teal;
    justify-content: flex-end !important;
    text-align: right !important;
  }

  .selected-location-name {
    font-size: 0.9rem;
    font-family: Apercu-Medium;
    color: $navy;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 10.5rem;
    display: block;
    overflow: hidden;
    text-align: right;

    @media only screen and (max-width: 404px) {
      width: 5rem;
    }

    @media (min-width: 405px) and (max-width: 767px) {
      width: 9rem;
    }

    @media (min-width: 992px) {
      width: 12.5rem;
    }
  }
}

.nav-avatar {
  justify-content: center;
  align-self: center;
}

.nav-avatar:hover {
  cursor: pointer;
}

.card.nav-avatar-menu {
  justify-content: center;
  text-align: center;
  min-height: 2rem;
  width: 15rem;

  .nav-admin-link {
    font-size: 1rem;
    cursor: pointer;
    opacity: 0.8;
    color: $default;
  }

  .nav-admin-link:hover {
    text-decoration: underline;
    opacity: 0.8;
    color: $dark-gray;
  }

  h3 {
    color: $navy;
    font-size: 1.1rem;
    position: relative;
  }

  h4 {
    color: $default;
    font-size: 0.75rem;
    padding: 0.5rem;
  }

  a {
    color: $primary-dark;
    font-family: Apercu-Bold;
  }

  a:hover {
    color: $primary-dark;
    text-decoration: underline;
    cursor: pointer;
  }
}
