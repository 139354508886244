@import "../../../App.scss";

.Login {
  margin-top: 4rem;

  #okta-sign-in.auth-container .button-primary {
    background-color: $navy !important;
    background: $navy;
  }

  #okta-sign-in .auth-org-logo {
    margin: -0.5rem auto;
  }
}

#okta-sign-in.auth-container.main-container {
  box-shadow: 0px;
  border: none;
  border-radius: 0;;
}